<template>
  <div>
    <strong>สิทธิการใช้งาน</strong>
    <div>
      <span class="text-danger">ซ่อน</span> = ซ่อนเมนู ไม่สามารถดูข้อมูลได้<br />
      <span class="text-danger">ดู</span> = ดูได้อย่างเดียว ไม่สามารถเพิ่ม แก้ไข หรือลบ<br />
      <span class="text-danger">อนุญาติ</span> = สามารถ ดู เพิ่ม แก้ไข หรือลบ
    </div>
    <div class="mt-2">
      <div class="d-flex justify-content-between mb-1">
        <div class="text-primary">1. รายงานการแทง</div>
        <b-form-radio-group v-model="input.permission.BetReport">
          <b-form-radio value="h">ซ่อน</b-form-radio>
          <b-form-radio value="r">ดู</b-form-radio>
        </b-form-radio-group>
      </div>
      <b-collapse id="collapse-bet-report" :visible="input.permission.BetReport==='r'">
        <div class="border-pp alert-pp rounded px-2 pb-2">
          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">1.1 รอผลเดิมพัน</span>
            <b-form-radio-group v-model="input.permission.WaitReport">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
            </b-form-radio-group>
          </div>
          <!-- <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">1.2 ดูของรวม</span>
            <b-form-radio-group v-model="input.permission.OverallReport">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
            </b-form-radio-group>
          </div> -->
          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">1.2 ดูของรวม/คาดคะเนได้-เสีย</span>
            <b-form-radio-group v-model="input.permission.WinLose">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
            </b-form-radio-group>
          </div>
          <!-- <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">1.4 ตรวจสอบยอด</span>
            <b-form-radio-group v-model="input.permission.WinLose2">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
            </b-form-radio-group>
          </div> -->
          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">1.3 ดูของสมาชิก/ประเภท</span>
            <b-form-radio-group v-model="input.permission.MemberReport">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
            </b-form-radio-group>
          </div>
          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">1.4 รายการโพย/ยกเลิกโพย</span>
            <b-form-radio-group v-model="input.permission.TicketReport">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
              <b-form-radio value="w">อนุญาติ <small class="text-secondary">ดูและยกเลิกโพย</small></b-form-radio>
            </b-form-radio-group>
          </div>
          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">1.5 เลขอั้น</span>
            <b-form-radio-group v-model="input.permission.LimitNumbers">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </b-collapse>
    </div>

    <div class="mt-2">
      <div class="d-flex justify-content-between mb-1">
        <div class="text-primary">2. รายงาน แพ้-ชนะ</div>
        <b-form-radio-group v-model="input.permission.WinLoseReport">
          <b-form-radio value="h">ซ่อน</b-form-radio>
          <b-form-radio value="r">ดู</b-form-radio>
        </b-form-radio-group>
      </div>
      <b-collapse id="collapse-winlose-report" :visible="input.permission.WinLoseReport==='r'">
        <div class="border-pp alert-pp rounded px-2 pb-2">
          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">2.1 สรุปยอด แพ้-ชนะ</span>
            <b-form-radio-group v-model="input.permission.MarketWinLose">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
            </b-form-radio-group>
          </div>
          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">2.2 แพ้-ชนะ สมาชิก/ประเภท</span>
            <b-form-radio-group v-model="input.permission.MemberTypeWinLose">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
            </b-form-radio-group>
          </div>
          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">2.3 โพยถูกรางวัล</span>
            <b-form-radio-group v-model="input.permission.WonTickets">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
            </b-form-radio-group>
          </div>
          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">2.4 ผลการออกรางวัล</span>
            <b-form-radio-group v-model="input.permission.Results">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </b-collapse>
    </div>

    <div class="mt-2">
      <div class="d-flex justify-content-between mb-1">
        <div class="text-primary">3. ตั้งค่างวดหวย</div>
        <b-form-radio-group v-model="input.permission.RoundSetting">
          <b-form-radio value="h">ซ่อน</b-form-radio>
          <b-form-radio value="r">ดู</b-form-radio>
        </b-form-radio-group>
      </div>
      <b-collapse id="collapse-round-setting" :visible="input.permission.RoundSetting==='r'">
        <div class="border-pp alert-pp rounded px-2 pb-2">

          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">3.1 ตั้งค่าอัตราจ่าย</span>
            <b-form-radio-group v-model="input.permission.Payrate">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
              <b-form-radio value="w">แก้ไข</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">3.2 ตั้งค่ารับของ (เฉพาะงวด)</span>
            <b-form-radio-group v-model="input.permission.RoundReceive">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
              <b-form-radio value="w">แก้ไข</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">3.3 ตั้งค่ารับของ (ตามชนิด)</span>
            <b-form-radio-group v-model="input.permission.MarketReceive">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
              <b-form-radio value="w">แก้ไข</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">3.4 ประวัติการตั้งค่า</span>
            <b-form-radio-group v-model="input.permission.SettingHistory">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
            </b-form-radio-group>
          </div>

        </div>
      </b-collapse>
    </div>

    <div class="mt-2">
      <div class="d-flex justify-content-between mb-1">
        <div class="text-primary">4. รายงานบัญชี</div>
        <b-form-radio-group v-model="input.permission.Finance">
          <b-form-radio value="h">ซ่อน</b-form-radio>
          <b-form-radio value="r">ดู</b-form-radio>
        </b-form-radio-group>
      </div>
      <b-collapse id="collapse-finance" :visible="input.permission.Finance==='r'">
        <div class="border-pp alert-pp rounded px-2 pb-2">

          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">4.1 รายงานการเงิน</span>
            <b-form-radio-group v-model="input.permission.FinanceReport">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">4.2 การเงินสมาชิก</span>
            <b-form-radio-group v-model="input.permission.FinanceMember">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
              <b-form-radio value="w">อนุญาติ <small class="text-secondary">ดูและโอนเงิน</small></b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">4.3 ประวัติการเข้าใช้งาน</span>
            <b-form-radio-group v-model="input.permission.AccountLog">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
            </b-form-radio-group>
          </div>

        </div>
      </b-collapse>
    </div>

    <div class="mt-2">
      <div class="d-flex justify-content-between mb-1">
        <div class="text-primary">5. จัดการสมาชิก</div>
        <b-form-radio-group v-model="input.permission.ManageAccount">
          <b-form-radio value="h">ซ่อน</b-form-radio>
          <b-form-radio value="r">ดู</b-form-radio>
        </b-form-radio-group>
      </div>
      <b-collapse id="collapse-manage-account" :visible="input.permission.ManageAccount==='r'">
        <div class="border-pp alert-pp rounded px-2 pb-2">

          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">5.1 เพิ่มเมมเบอร์</span>
            <b-form-radio-group v-model="input.permission.CreateMember">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="w">อนุญาติ</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">5.2 เพิ่มเอเย่นต์</span>
            <b-form-radio-group v-model="input.permission.CreateAgent">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="w">อนุญาติ</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">5.3 รายชื่อสมาชิก</span>
            <b-form-radio-group v-model="input.permission.MemberSetting">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
              <b-form-radio value="w">อนุญาติ <small class="text-secondary">ดู และแก้ไขข้อมูล</small></b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">5.3 รายชื่อที่ถูกระงับ</span>
            <b-form-radio-group v-model="input.permission.BlockMember">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
              <b-form-radio value="w">อนุญาติ</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">5.4 ตั้งค่าสมาชิกใหม่</span>
            <b-form-radio-group v-model="input.permission.NewMemberSetting">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
              <b-form-radio value="w">อนุญาติ</b-form-radio>
            </b-form-radio-group>
          </div>

        </div>
      </b-collapse>
    </div>

    <div class="mt-2">
      <div class="d-flex justify-content-between mb-1">
        <div class="text-primary">6. ตั้งค่าสมาชิก</div>
        <b-form-radio-group v-model="input.permission.AccountSetting">
          <b-form-radio value="h">ซ่อน</b-form-radio>
          <b-form-radio value="r">ดู</b-form-radio>
        </b-form-radio-group>
      </div>
      <b-collapse id="collapse-account-setting" :visible="input.permission.AccountSetting==='r'">
        <div class="border-pp alert-pp rounded px-2 pb-2">

          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">6.1 เปิด/ปิด หวย</span>
            <b-form-radio-group v-model="input.permission.MemberMarket">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
              <b-form-radio value="w">อนุญาติ</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">6.2 แบ่งหุ้น/เก็บของ</span>
            <b-form-radio-group v-model="input.permission.MemberSharing">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
              <b-form-radio value="w">อนุญาติ</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">6.3 เปิด/ปิด/ตั้งค่า อัตราจ่าย</span>
            <b-form-radio-group v-model="input.permission.MemberPayout">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
              <b-form-radio value="w">อนุญาติ</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">6.4 ขั้นต่ำ/สูงสุด/สูงสุดต่อเลข</span>
            <b-form-radio-group v-model="input.permission.MemberBetLimit">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
              <b-form-radio value="w">อนุญาติ</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="mt-2 d-flex justify-content-between">
            <span class="text-secondary">6.5 ตั้งค่าหวยชุด</span>
            <b-form-radio-group v-model="input.permission.MemberLottoSet">
              <b-form-radio value="h">ซ่อน</b-form-radio>
              <b-form-radio value="r">ดู</b-form-radio>
              <b-form-radio value="w">อนุญาติ</b-form-radio>
            </b-form-radio-group>
          </div>

        </div>
      </b-collapse>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Permission',
  props: ['input']
}
</script>
